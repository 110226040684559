import React from 'react';

const Profile = () => {
    return (
        <div className="pt-5 pb-5 bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600 min-h-screen flex items-center justify-center">
            <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8 mt-20">
                <header className="text-center mb-6">
                    <h1 className="text-3xl font-bold text-gray-800 mt-4">SALHI AICHA</h1>
                    <h2 className="text-xl font-medium text-gray-500">Traductrice Professionnelle Assermentée</h2>
                    <h2 className="text-xl font-medium text-gray-500">Spécialiste en Arabe et Français</h2>
                </header>

                <section className="text-center mb-8">
                    <h3 className="text-2xl font-semibold text-gray-700 mb-4">À propos de moi</h3>
                    <p className="text-gray-600 leading-relaxed">
                        Je suis traductrice certifiée en arabe et en français, diplômée de l'ITIRI (Université de Strasbourg) et assermentée près de la Cour d'Appel de Dijon.
                        Spécialisée dans les domaines juridique et administratif, j'ai acquis plusieurs années d'expérience et une solide expertise en traduction de documents tels que contrats,
                        actes notariés et certificats.
                    </p>
                </section>

                <section className="mb-8">
                    <h3 className="text-2xl font-semibold text-gray-700 mb-4">Mes Services</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="bg-gray-100 p-4 rounded-lg text-center text-gray-700">
                            Traduction Juridique
                        </div>
                        <div className="bg-gray-100 p-4 rounded-lg text-center text-gray-700">
                            Traduction Administrative
                        </div>
                        <div className="bg-gray-100 p-4 rounded-lg text-center text-gray-700">
                            Légalisation de Documents
                        </div>
                        <div className="bg-gray-100 p-4 rounded-lg text-center text-gray-700">
                            Révisions et Corrections
                        </div>
                    </div>
                </section>

                <section className="mb-8">
                    <h3 className="text-2xl font-semibold text-gray-700 mb-4">Engagement envers la Qualité</h3>
                    <p className="text-gray-600 leading-relaxed">
                        Je m’engage à fournir des traductions précises et conformes, avec une attention particulière aux détails. Chaque projet est traité avec le plus grand soin pour garantir la conformité avec les exigences légales.
                    </p>
                </section>

                <section className="mb-8">
                    <h3 className="text-2xl font-semibold text-gray-700 mb-4">Confidentialité</h3>
                    <p className="text-gray-600 leading-relaxed">
                        La confidentialité de vos informations est une priorité. Je m'engage à traiter tous les documents avec la plus grande discrétion. Aucune information ne sera partagée sans votre consentement explicite.
                    </p>
                </section>

                <section className="text-center">
                    <h3 className="text-2xl font-semibold text-gray-700 mb-4">Contact</h3>
                    <p className="text-gray-600">
                        Pour toute demande de renseignements, vous pouvez me contacter par e-mail à <span className="text-blue-600">salhi.aicha@traductionenarabe.fr</span>.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default Profile;
